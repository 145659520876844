import React from "react";
import "./App.css";
import Login from "./Login";
import BookingCheck from "./BookingCheck";

export const AuthContext = React.createContext(); 

const initialState = {
  isAuthenticated: localStorage.getItem("token") ? true : false,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  user: localStorage.getItem("user") ? localStorage.getItem("user") : null,
};
const reducer = (state, action) => {
  console.log("--->", action.payload.user);
  switch (action.type) {
    case "LOGIN":
      let token = action.payload.token;
      let user = action.payload.user;
      if(token){
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("user", JSON.stringify(user));
      }else{
        token = null;
      }
      return {
        ...state,
        isAuthenticated: token ? true : false,
        token
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch
      }}
      >
      {!state.isAuthenticated ? <Login /> : <BookingCheck />}
    </AuthContext.Provider>
  );
}
export default App;