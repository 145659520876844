import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import TextField from '@material-ui/core/TextField';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import IconButton from '@material-ui/core/IconButton';
import { it } from 'date-fns/locale'

import { format, add, compareAsc } from 'date-fns'


import axios from 'axios';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { AuthContext } from "./App";

import { API_URL, APP_URL} from "./Config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography variant="h6" component="h2" gutterBottom>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    //display: 'flex',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.secondary,
  },
  MuiGridContainer: {
    display: "block",
    width: "auto",
  },
  toolbar: {
    backgroundColor: '#ffffff',
    paddingLeft: 5,
    paddingRight: 5,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  score: {
    fontSize: 12,
    marginBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 40,
    marginTop: 15,
    marginRight: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: '#F9F9F9',
    marginBottom: 10,
  },
  paperActive: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    backgroundColor: '#FFF4CC',
    marginBottom: 10,
  },
  fab1: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fab2: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
  inputText: {
    width: 320,
  }
}));

export default function BookingCheck() {
  const { dispatch } = React.useContext(AuthContext);

  const [userData, setUserData] = React.useState("");
  const [arrProject, setArrProject] = React.useState([]);

  const [arrBooking, setArrBooking] = React.useState([
    /*
    {
      'name': 'Hotel Derby Rimini',
      'url': 'https://www.booking.com/hotel/it/derby-rimini.it.html'
    },
    {
      'name': 'Hotel Aristeo',
      'url': 'https://www.booking.com/hotel/it/aristeo.it.html'
    },
    {
      'name': 'Remin Plaza',
      'url': 'https://www.booking.com/hotel/it/reminplaza.it.html'
    },
    {
      'name': 'Park Hotel Rimini',
      'url': 'https://www.booking.com/hotel/it/parkhotelrimini.it.html'
    }
    */
  ]);


  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserData(user.name)
    loadProjects();
  }, []);


  const loadProjects = async() => {

    const token = JSON.parse(localStorage.getItem("token"));
    return axios.get(API_URL+'/api/projects',{
        headers: { 
          'Authorization': "Bearer "+token,
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        } 
    })
    .then(response => {
      setArrProject(response.data);
      console.log("loadProjects: ", response.data)
    })
    .catch(function (error) {
      console.log("CATCH", error.response.data.message);
      setOpenLoading(false);

      if(error.response.data.message == "Token has expired"){
        dispatch({
          type: "LOGOUT",
          payload: {}
        })
      }
    });
  }

  const loadCustomers = async() => {
    const token = JSON.parse(localStorage.getItem("token"));
    return axios.get(API_URL+'/api/projects/'+selectedProject.id+'/customers',{
        headers: { 
          'Authorization': "Bearer "+token,
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        } 
    })
    .then(response => {
      setArrBooking(response.data);
      console.log("loadProjects: ", response.data)
    })
    .catch(function (error) {
      console.log("CATCH", error.response.data.message);
      setOpenLoading(false);

      if(error.response.data.message == "Token has expired"){
        dispatch({
          type: "LOGOUT",
          payload: {}
        })
      }
    });
  }

  const selectProject = async(projectId) => {
    const token = JSON.parse(localStorage.getItem("token"));
    return axios.get(API_URL+'/api/projects/'+projectId+'/customers',{
        headers: { 
          'Authorization': "Bearer "+token,
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        } 
    })
    .then(response => {
      setArrBooking(response.data);
      console.log("loadProjects: ", response.data)
    })
    .catch(function (error) {
      console.log("CATCH", error.response.data.message);
      setOpenLoading(false);

      if(error.response.data.message == "Token has expired"){
        dispatch({
          type: "LOGOUT",
          payload: {}
        })
      }
    });
  }

  const [name, setName] = React.useState("");
  const [url, setUrl] = React.useState("");

  const handleChangeName = event => {
    setName(event.target.value)
  }

  const handleChangeUrl = event => {
    setUrl(event.target.value)
  }


  
  const addProject = async() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      user_id: user.id,
      name,
      url
    };

    axios.post(API_URL+'/api/projects/create', data, {
        headers: { 
          'Authorization': "Bearer "+token,
          'Content-Type': 'application/json'
        } 
    })
    .then(response => {
      if(response.data.success == true){
        loadProjects();
      }else{
        console.log("Errore addProject: ", response.data)
      }
      handleCloseAddProjectModal();
    })
    .catch(function (error) {
      console.log(error);
      setOpenLoading(false);
    });
    
  }


  const deleteProject = async() => {
    const token = JSON.parse(localStorage.getItem("token"));
    axios.delete(API_URL+'/api/projects/delete/'+selectedProject.id, {
        headers: { 
          'Authorization': "Bearer "+token,
          'Content-Type': 'application/json'
        } 
    })
    .then(response => {
      if(response.data.success == true){
        setSelectedProject({id: "", name: "", url: ""});
        loadProjects();
      }else{
        console.log("Errore deleteProject: ", response.data)
      }
      handleCloseDeleteProjectModal();
    })
    .catch(function (error) {
      console.log(error);
      setOpenLoading(false);
    });
    
  }




  const addCustomer = async() => {
    console.log(API_URL+'/api/projects/'+selectedProject.id+'/customers/create')
    const token = JSON.parse(localStorage.getItem("token"));
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      project_id: selectedProject.id,
      name,
      url
    };

    axios.post(API_URL+'/api/projects/'+selectedProject.id+'/customers/create', data, {
        headers: { 
          'Authorization': "Bearer "+token,
          'Content-Type': 'application/json'
        } 
    })
    .then(response => {
      if(response.data.success == true){
        loadCustomers();
      }else{
        console.log("Errore addCustomer: ", response.data)
      }
      handleCloseAddCustomerModal();
    })
    .catch(function (error) {
      console.log(error);
      setOpenLoading(false);
    });
    
  }
  


  const deleteCustomer = async() => {
    const token = JSON.parse(localStorage.getItem("token"));
    axios.delete(API_URL+'/api/projects/'+selectedProject.id+'/customers/delete/'+selectedCustomer.id, {
        headers: { 
          'Authorization': "Bearer "+token,
          'Content-Type': 'application/json'
        } 
    })
    .then(response => {
      if(response.data.success == true){
        loadCustomers();
      }else{
        console.log("Errore deleteCustomer: ", response.data)
      }
      handleCloseDeleteCustomerModal();
    })
    .catch(function (error) {
      console.log(error);
      setOpenLoading(false);
    });
    
  }




  const classes = useStyles();
  
  const [valueResult, setValueResult] = React.useState("");
  const handleChangeResult = (event, newValue) => {
    setValueResult(newValue);
  };

  const [selectedProject, setSelectedProject] = React.useState({id: '', name: ''});
  const [openDeleteProject, setOpenDeleteProject] = React.useState(false);
  const handleOpenDeleteProjectModal = () => {
    setOpenDeleteProject(true);
  };
  const handleCloseDeleteProjectModal = () => {
    setOpenDeleteProject(false);
  };

  const handleChangeProject = (event, newProject) => {
    setArrayData([]);
    setSelectedProject({id: newProject.props.value, name: newProject.props.children, url: newProject.props.url});
    console.log(newProject)
    selectProject(newProject.props.value)
  };



  
  const [selectedCustomer, setSelectedCustomer] = React.useState({name: '', url: ''});
  const [openDeleteCustomer, setOpenDeleteCustomer] = React.useState(false);
  const handleCloseDeleteCustomerModal = () => {
    setOpenDeleteCustomer(false);
  };






  const hanldeDeleteCustomer = (id) => {
    setSelectedCustomer(id)
    setOpenDeleteCustomer(true);
  }

  const [openShow, setOpenShow] = React.useState(false);
  const hanldeOpenShowCustomer = (item) => {
    setSelectedCustomer(item)
    setOpenShow(true);
  }
  const hanldeCloseShowCustomer = () => {
    setOpenShow(false);
  }


  const [openLogout, setOpenLogout] = React.useState(false);
  const handleOpenLogoutModal = () => {
    setOpenLogout(true);
  };
  const handleCloseLogoutModal = () => {
    setOpenLogout(false);
  };

  const [openAddProject, setopenAddProject] = React.useState(false);
  const handleOpenAddProjectModal = () => {
    setopenAddProject(true);
  };
  const handleCloseAddProjectModal = () => {
    setopenAddProject(false);
  };

  const [openAddCustomer, setopenAddCustomer] = React.useState(false);
  const handleOpenAddCustomerModal = () => {
    setopenAddCustomer(true);
  };
  const handleCloseAddCustomerModal = () => {
    setopenAddCustomer(false);
  };

  let dateFrom = new Date();
  let dateTo = new Date();
  dateFrom = dateFrom.setDate(dateFrom.getDate() + 1);
  dateTo = dateTo.setDate(dateTo.getDate() + 2);

  const [selectedDateFrom, setSelectedDateFrom] = React.useState(dateFrom);
  const [selectedDateTo, setSelectedDateTo] = React.useState(dateTo);

  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date);
    setSelectedDateTo( add(new Date(date), {days: 1}));
  };
  const handleDateToChange = (date) => {
    setSelectedDateTo(date);
    const result = compareAsc(new Date(date), new Date(selectedDateFrom))
    if(result < 1){
      setSelectedDateFrom( add(new Date(date), {days: -1}));
    }
  };

  const [adults, setAdults] = React.useState(2);
  const handleAdultsChange = (event) => {
    setAdults(event.target.value);
  };

  const [children, setChildren] = React.useState(0);
  const handleChildrenChange = (event) => {
    setChildren(event.target.value);
    let arr = []
    for(let a=1; a <= event.target.value; a++){
      arr.push({name:"age"+a, value: 0})
    }
    setChildrenAge(arr);

    setNumChildren(Array.from(Array(event.target.value).keys()))
  
  };

  const [numChildren, setNumChildren] = React.useState([]);

  const [childrenAge, setChildrenAge] = React.useState([]);
  const handleAgeChildrenChange = (event) => {
    //console.log("childrenAge", childrenAge)
    //console.log("target", event.target)
    let arr = childrenAge.map(item => {
      if(item.name === event.target.name){
        item.value = event.target.value
      }
      return item
    })
    setChildrenAge(arr)
  }
 
  const [openLoading, setOpenLoading] = React.useState(false);
  const handleCloseLoading = () => {
    setOpenLoading(false);
  };

  const [arrayData, setArrayData] = React.useState([]);

  const getData = async() => {
    setOpenLoading(true);
    
    const from = format(new Date(selectedDateFrom), 'yyyy-MM-dd');
    const to = format(new Date(selectedDateTo), 'yyyy-MM-dd');

    let arrayBookingUrl = [];

    let arrayData = [];
    arrayData.push(selectedProject);
    arrayData = arrayData.concat(arrBooking);

    console.log(selectedProject);
    console.log(arrBooking);
    console.log(arrayData)

    const arrayResults = arrayData.map((item, key) => {
      let url = item.url;
      url += "?checkin="+from;
      url += ";checkout="+to;
      url += ";req_adults="+adults;
      url += ";req_children="+children;
      
      let room1 = "";

      for(let a=0; a < adults; a++){
        room1+="A%2C";
      }

      childrenAge.forEach(child => {
        room1+=child.value+"%2C";
      })

      url += ";room1="+room1;
      arrayBookingUrl.push(
        axios.post(APP_URL+'/server.php', {
          url,
          key,
          headers : {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
         }
        }));
    })

    

     
    axios.all(arrayBookingUrl).then(axios.spread((...responses) => {
      
      formatData(responses);
      // use/access the results 
    })).catch(errors => {
      // react on errors.
    })



  }

  const formatData = (result) => {


    console.log("result:", result)
    let arrayTab = [];

    
    result.forEach(item => {

      if(item.data){
        if(item.data.camere){
          item.data.camere.forEach(camera => {

            console.log("camera: ", camera);
            if(camera.persone === adults || adults === 4 && camera.persone >= adults){
              let find = false;
              arrayTab.forEach((arr, key) => {
                if(arr.title === camera.trattamento){
                  find = true;
                }
              })
      
              if(!find){
                arrayTab.push({title: camera.trattamento, strutture: []})
              }
      
              arrayTab.forEach((arr, key) => {
                if(arr.title === camera.trattamento){
                  if(!arr.strutture[camera.key]){
                    arr.strutture[camera.key] = [];
                  }
                  arr.strutture[camera.key].push(camera);
                }
              })
            }
    
          })
          console.log("arrayTab: ", arrayTab)
          setArrayData(arrayTab);
       }

      }

    })

    setValueResult(0);

    setOpenLoading(false);
  }

  const logoutUser = event => {
    event.preventDefault();


    axios.get(API_URL+'/api/logout', {
      token: localStorage.getItem("token"),
        headers : {
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      })
    .then(response => {
      dispatch({
        type: "LOGOUT",
        payload: response.data
      })
    })
    .catch(function (error) {
      alert( error.message || error.statusText)
    });



  };

  return (
    <div className={classes.root}>
      <div style={{flexDirection: "row"}}>
        
        <Grid container alignItems="center" style={{padding: 10}}>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-label">Seleziona un progetto</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  //value={age}
                  label=">Seleziona un progetto"
                  onChange={handleChangeProject}
                  style={{minWidth: 200, marginRight: 20}}
                >
                  
                {arrProject.map((item, index) => {
                  return(
                    <MenuItem value={item.id} url={item.url}>{item.name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            {arrBooking.map((item, index) => {
              return(
                <Chip
                  key={index}
                  label={item.name}
                  clickable
                  onClick={() => hanldeOpenShowCustomer(item)}
                  style={{fontSize: 16, height: 56, paddingVertical: 20, paddingRight: 10, paddingLeft: 10, marginRight: 10, borderRadius: 5, backgroundColor: index == 0 ? "#FFF4CC" : "#FFF4CC"}}
                  onDelete={() => hanldeDeleteCustomer(item)}
                />
              )
            })}
          {selectedProject.id > 0 &&
            <IconButton 
              color="secondary" 
              component="span" 
              style={{background: '#FECDCB'}}
              onClick={handleOpenAddCustomerModal}
              >
              <AddIcon />
            </IconButton>
          }

          </Grid>
          <Grid item>
          <Typography variant="button" style={{position: 'relative', top: 1, marginRight: 10}}>Benvenuto {userData}</Typography>|
            <IconButton onClick={handleOpenLogoutModal} size="medium">
              <MeetingRoomIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>



      </div>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>

            <FormControl className={classes.formControl}>
              <InputLabel>
                Adulti
              </InputLabel>
              <Select
                value={adults}
                onChange={handleAdultsChange}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel>
                Bambini
              </InputLabel>
              <Select
                value={children}
                onChange={handleChildrenChange}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>

            {numChildren.map(i => {

              let item = childrenAge.filter((element) => {return element.name === "age"+(i+1)});

              if(item.length > 0){
                return(
                  <FormControl key={i} className={classes.formControl}>
                    <InputLabel>
                      Età {i+1}
                    </InputLabel>
                    <Select
                      value={item[0].value}
                      onChange={handleAgeChildrenChange}
                      name={item[0].name}
                      displayEmpty
                      className={classes.selectEmpty}
                    >
                      {Array.from(Array(18), (e, i) => (
                        <MenuItem key={i} value={i}>{i}</MenuItem>
                      ))}

                    </Select>
                  </FormControl>
                )
              }
              return null
            })}


            <MuiPickersUtilsProvider locale={it} utils={DateFnsUtils}>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-from"
                label="Arrivo"
                value={selectedDateFrom}
                onChange={handleDateFromChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>


            <MuiPickersUtilsProvider locale={it} utils={DateFnsUtils}>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-to"
                label="Arrivo"
                value={selectedDateTo}
                onChange={handleDateToChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
          </MuiPickersUtilsProvider>
          <Button 
            disabled={arrBooking.length == 0 ? true : false} 
            style={{marginLeft: 20}} 
            variant="contained" 
            color="secondary" 
            size="large"
            onClick={() => getData()}>
             Ricerca su Booking.com
          </Button>

        </Toolbar>
        <Tabs
          //orientation="vertical"
          variant="scrollable"
          
          value={valueResult}
          onChange={handleChangeResult}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          >
          {arrayData.map((item, key) => (
            <Tab key={key} label={item.title} wrapped />
          ))}
        </Tabs>
      </AppBar>

      {arrayData.map((item, key) => (
        <TabPanel value={valueResult} index={key} key={key}>
          <Grid container index={key} spacing={1}>   
            {item.strutture.map((struttura, id) => (
            <Grid key={id} item xs={12} md={6} lg={2}>
              <Typography variant="button" component="h2" gutterBottom>
                {struttura[0].struttura} - {struttura[0].media}
              </Typography>
              <Typography variant="body2" gutterBottom className={classes.score}>
                {struttura[0].punteggio}
              </Typography>
              {struttura.map((elm, index) => (
                <Card classes={classes} key={index} item={elm} />
              ))}
            </Grid>
            ))}
          </Grid>
        </TabPanel> 
      ))}

      <Dialog
        open={openAddProject}
        onClose={handleCloseAddProjectModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Crea nuovo progetto"}</DialogTitle>
        <DialogContent>
          <TextField fullWidth label="Nome struttura" onChange={handleChangeName} className={classes.inputText} />
        </DialogContent>
        <DialogContent>
          <Typography variant="caption" >Es: Hotel Prova</Typography>
        </DialogContent>
        <DialogContent>
          <TextField fullWidth label="Indirizzo booking.com" onChange={handleChangeUrl} className={classes.inputText} />
        </DialogContent>
        <DialogContent>
          <Typography variant="caption" >Es: https://www.booking.com/hotel/it/prova.it.html</Typography>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseAddProjectModal} color="primary">
            Annulla
          </Button>
          <Button onClick={addProject} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openAddCustomer}
        onClose={handleCloseAddCustomerModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">{"Aggiungi cliente da comparare"}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" >Progetto: {selectedProject.name}</Typography>
        </DialogContent>
        <DialogContent>
          <TextField fullWidth label="Nome struttura" onChange={handleChangeName} className={classes.inputText} />
        </DialogContent>
        <DialogContent>
          <Typography variant="caption" >Es: Hotel Prova</Typography>
        </DialogContent>
        <DialogContent>
          <TextField fullWidth label="Indirizzo booking.com" onChange={handleChangeUrl} className={classes.inputText} />
        </DialogContent>
        <DialogContent>
          <Typography variant="caption" >Es: https://www.booking.com/hotel/it/prova.it.html</Typography>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseAddCustomerModal} color="primary">
            Annulla
          </Button>
          <Button onClick={addCustomer} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteCustomer}
        onClose={handleCloseDeleteCustomerModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancellazione indirizzo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler cancellare questo indirizzo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteCustomerModal} color="primary">
            Annulla
          </Button>
          <Button onClick={deleteCustomer} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteProject}
        onClose={handleCloseDeleteProjectModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cancellazione indirizzo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler cancellare il progetto "{selectedProject.name}" e tutti i clienti al suo interno?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteProjectModal} color="primary">
            Annulla
          </Button>
          <Button onClick={deleteProject} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLogout}
        onClose={handleCloseLogoutModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logout utente"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sei sicuro di voler effetturale il logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutModal} color="primary">
            Annulla
          </Button>
          <Button onClick={logoutUser} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openShow}
        onClose={hanldeCloseShowCustomer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Dettaglio indirizzo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Nome: {selectedCustomer.name}<br/>Url: {selectedCustomer.url}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hanldeCloseShowCustomer} color="primary">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
      
      <Fab variant="extended"  color="secondary" aria-label="add" className={classes.fab1} onClick={handleOpenAddProjectModal}>
        <AddIcon />
        Aggiungi progetto
      </Fab>
      {selectedProject.id > 0 &&
        <Fab variant="extended" color="primary" aria-label="delete" className={classes.fab2} onClick={handleOpenDeleteProjectModal}>
          <DeleteIcon />
          Elimina progetto
        </Fab>
      }
      <Backdrop className={classes.backdrop} open={openLoading} onClick={handleCloseLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}


function Card(props, data){

  const classes = props.classes;
  const item = props.item;

  return(
    <Paper className={item.key === 0 ? classes.paperActive : classes.paper}>
      <Typography variant="subtitle2" >
        {item.camera}
      </Typography>
      <Typography variant="caption" >
        Persone: {item.persone}
      </Typography>
      <Typography variant="caption" display="block" gutterBottom style={{lineHeight: 2}}>
        <div dangerouslySetInnerHTML={{__html: item.condizioni}}/>
      </Typography>
      <div style={{backgroundColor: '#DAE1FB', marginTop: 10, paddingTop: 4, paddingLeft: 8, paddingBottom: 4, paddingRight: 8, borderRadius: 10}}>
        <Typography variant="subtitle2" ><strong>Prezzo:  {item.prezzo}</strong></Typography>
      </div>
    </Paper>
  )
}