let API_URL_CONF;
let APP_URL_CONF;

/*
if(window.location.hostname == "localhost"){
    API_URL_CONF = 'https://bookingcheck_admin.test';
    APP_URL_CONF = 'https://bookingcheck.test';
}else{
    API_URL_CONF = 'https://bookingcheck.edita.it';
    APP_URL_CONF = 'https://bookingcheck.edita.it';
    
}
*/

API_URL_CONF = 'https://bookingcheck.edita.it';
APP_URL_CONF = 'https://bookingcheck.edita.it';

export const API_URL = API_URL_CONF
export const APP_URL = APP_URL_CONF;
