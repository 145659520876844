import React from "react";
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { AuthContext } from "./App";

import { API_URL} from "./Config";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    display: 'flex',
    marginTop: 100,
  },
  card: {
    width: 300,
  },
  label: {
    display: 'flex',
    marginBottom: 10,
  },
  input: {
    padding: 5,
    width: '100%',
  },
  title: {
    fontSize: 14,
  },
  button: {
    justifyContent: "flex-end",
  },
  errorMessage: {
    color: '#ff0000',
  }
}));

export const Login = () => {

  const { dispatch } = React.useContext(AuthContext);
  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
    errors: {},
  };
  const [data, setData] = React.useState(initialState);
  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };
  const handleFormSubmit = event => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null
    });
    axios.post(API_URL+'/api/login', {
      email: data.email,
      password: data.password,
      headers : {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
    .then(response => {
      dispatch({
        type: "LOGIN",
        payload: response.data
      })
      setData({
        ...data,
        isSubmitting: false,
        errors: response.data.token ? [] : response.data.error
        
      });
    })
    .catch(function (error, aaa) {
      setData({
        ...data,
        isSubmitting: false,
        errors: ["Errore di autenticazione, verificare indirizzo email e password"]
      });
    });
  };

  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <form>
            <Typography variant="h6" gutterBottom>Login</Typography>
            <label className={classes.label} htmlFor="email">
              <input
                type="text"
                className={classes.input}
                value={data.email}
                onChange={handleInputChange}
                placeholder="email"
                name="email"
                id="email"
              />
            </label>
            <label className={classes.label} htmlFor="password">
              <input
                type="password"
                className={classes.input}
                value={data.password}
                onChange={handleInputChange}
                placeholder="password"
                name="password"
                id="password"
              />
            </label>
          </form>
          {data.errors && 
          Object.keys(data.errors).map((item, i) => (
            <Typography variant="caption" display="block" className={classes.errorMessage}>{data.errors[item]}</Typography>
          ))
        }
        </CardContent>
        <CardActions className={classes.button}>
          <Button 
            onClick={handleFormSubmit} 
            variant="contained" 
            color="secondary" 
            disabled={data.isSubmitting}>
            Login
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};
export default Login;